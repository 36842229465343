<template>
  <div id="CommonBoardsWrap">
    <div class="top">
      <div class="title">FEATURES</div>
      <template v-for="(item, i) in articleTypeOptions">
        <div
          class="menu"
          :key="i"
          :class="{ active: item.value === articleInfo.type }"
          @click="onClickMenu(item.value)"
        >
          {{ item.label }}
        </div>
      </template>
    </div>
    <div class="article-title">
      {{
        activeLanguage === "ko" ? articleInfo.title : articleInfo.titleEn
      }}
    </div>
    <div class="article">
      <template v-if="activeLanguage === 'ko'">
        <div
          class="ck ck-content content-box-content"
          v-html="articleInfo.content !== undefined && articleInfo.content !== null && articleInfo.content !== '' ? articleInfo.content : articleInfo.contentEn"
        ></div>
      </template>
      <template v-else>
        <div
          class="ck ck-content content-box-content"
          v-html="articleInfo.contentEn !== undefined && articleInfo.contentEn !== null && articleInfo.contentEn !== '' ? articleInfo.contentEn : articleInfo.content"
        ></div>
      </template>
    </div>
    <router-link to="/features" class="back-btn">
      GO TO LIST
    </router-link>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const featuresHelper = createNamespacedHelpers("features");

import "/src/assets/scss/vendors/editor/content-styles.css";

export default {
  name: "FeatureDetail",
  components: {},
  props: {
    idx: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      articleTypeOptions: [
        { value: "FeaturedArtist", label: "Featured artist" },
        { value: "WEB3", label: "Web 3.0" },
        { value: "NEWS", label: "News" },
        { value: "EVENT", label: "Event" },
      ],
    };
  },
  computed: {
    ...featuresHelper.mapState(["articleInfo"]),
    activeLanguage() {
      return this.getActiveLanguage();
    }
  },
  mounted() {
    this.requestArticleItem();
  },
  methods: {
    ...featuresHelper.mapActions(["getArticle"]),
    requestArticleItem() {
      if (this.idx && this.idx !== "0") {
        this.getArticle(this.idx).then(data => {
          //this.$log.info(data);
        });
      }
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onClickMenu(value) {
      this.$router.push({ name: "FeatureList", params: {articleType: value} });
    }
  }
};
</script>
